<template>
	<CRow
		:gutters="false"
		align-vertical="center"
	>
		<CCol md="7">
			<h4>{{ title }}</h4>
			<div class="typo-body-2 color-black-45">
				<span>{{ description }}</span>
			</div>
		</CCol>
		<CCol md="5" class="text-right">
			<router-link
				:to="{
					name: installmentPlanRouteName,
					params: {
						id: groupId,
						methodId: methodId,
						channelId: channelId,
					},
				}"
				class="btn btn-secondary"
			>
				<CIcon class="mr-2" name="cil-plus" /> Create plan
			</router-link>
		</CCol>
		<CCol md="12">
			<BaseNoItemBanner
				v-if="!plans.length"
				:text="noItemDescription"
				class="mt-4"
			/>
			<BaseTableInstallmentBAY
				v-else-if="isBAYBankAgent"
				router-edit-name="InstallmentPlanEdit"
				:plans="plans"
				:group-id="groupId"
				:method-id="methodId"
			/>
			<BaseTableInstallment
				v-else
				:plans="plans"
				:group-id="groupId"
				:method-id="methodId"
				:channel-id="channelId"
			/>
			<hr class="my-5">
		</CCol>
		<CCol v-if="!isBAYBankAgent && !isKTCBankAgent" md="12">
			<h4>
				Available SKUs for 0% installment plan
			</h4>
			<div class="typo-body-2 color-black-45">
				<span>
					Set available SKUs that can pay with 0% installment plan by export and import spreadsheet. Supported file type xls, xlsx.
				</span>
			</div>
			<BaseNoItemBanner
				v-if="!zeroPercentPlans.length"
				class="mt-4"
				text="There are no 0% installment plans."
			/>
			<table v-else class="table-installment w-100 typo-body-2 mt-4">
				<thead>
					<tr>
						<th class="w-20">
							Term
						</th>
						<th class="w-20">
							Available SKUs
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="plan in zeroPercentPlans" :key="plan.id">
						<td>{{ plan.period }} month(s)</td>
						<td>{{ plan.availableSkus }} SKU(s)</td>
						<td class="text-right">
							<CButton
								class="btn-export transparent"
								@click="handleExportPlanSKU(plan.id)"
							>
								<CIcon
									class="mr-2"
									name="cil-arrow-thick-from-bottom"
								/> Export
							</CButton>
							<label class="btn-import transparent">
								<CIcon class="mr-2" name="cil-arrow-thick-from-top" /> Import
								<input
									:ref="`file-import-${plan.id}`"
									:data-id="`file-import-${plan.id}`"
									type="file"
									class="d-none"
									accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
									@change="handleImportPlanFileChange($event, plan.id)"
								>
							</label>
						</td>
					</tr>
				</tbody>
			</table>
			<hr class="mt-5 mb-3">
			<BaseModalLoading :ref="refModalLoading" :modal-title="modalTitle" />
		</CCol>
	</CRow>
</template>

<script>
import { mapActions } from 'vuex';
import BaseTableInstallment from '@/components/BaseTableInstallment.vue';
import BaseTableInstallmentBAY from '@/components/BaseTableInstallmentBAY.vue';
import { importInstallmentPlanAPI } from '../services/api/import.api';
import { exportInstallmentPlanSkuAPI } from '../services/api/export.api';

export default {
	name: 'PaymentSettingInstallment',

	components: {
		BaseTableInstallment,
		BaseTableInstallmentBAY,
	},
	props: {
		groupId: {
			type: Number,
			default: null,
		},
		methodId: {
			type: Number,
			default: null,
		},
		plans: {
			type: Array,
			default: () => [],
		},
		channelId: {
			type: Number,
			default: null,
		},
		isBAYBankAgent: {
			type: Boolean,
			default: false,
		},
		isKTCBankAgent: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			refModalLoading: 'modal-loading',
			modalTitle: null,
		};
	},
	computed: {
		zeroPercentPlans() {
			return this.plans.filter((plan) => plan.interestRate === 0);
		},
		installmentPlanRouteName() {
			return this.channelId ? 'InstallmentPlanChannelCreate' : 'InstallmentPlanCreate';
		},
		title() {
			return this.isBAYBankAgent || this.isKTCBankAgent ? 'Default installment plan' : 'Installment plan';
		},
		description() {
			return this.isBAYBankAgent || this.isKTCBankAgent ? 'Create installment plans and condition such as interest rate, payments term, and campaign code for all products.' : 'Create installment plan and set plan detail such as interest rate, payments term, minimum order, and maximum order.';
		},
		noItemDescription() {
			return this.isBAYBankAgent || this.isKTCBankAgent ? 'There are no default installment plan.' : 'There are no installment plans.';
		},
	},
	methods: {
		...mapActions({
			showToast: 'toast/showToast',
		}),

		async handleImportPlanFileChange(event, planId) {
			const file = event.target.files[0];

			this.modalTitle = 'Importing';
			this.$refs[this.refModalLoading].open();

			try {
				await importInstallmentPlanAPI(file, planId);
				this.showToast({
					content: 'Installment plan successfully uploaded. It could take up to 30 minutes for the result to take effect.',
					header: 'Success',
					type: 'success',
				});
			} catch (e) {
				this.showToast({
					content: 'Installment plan upload failed',
					header: 'Failure',
					type: 'danger',
				});
			} finally {
				this.$refs[this.refModalLoading].close();
				this.$refs[`file-import-${planId}`][0].value = '';
			}
		},
		async handleExportPlanSKU(planId) {
			this.modalTitle = 'Exporting';
			this.$refs[this.refModalLoading].open();

			try {
				await exportInstallmentPlanSkuAPI(planId);
			} finally {
				this.$refs[this.refModalLoading].close();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.table-installment {
		color: $color-black-90;

		tbody {
			box-shadow: 0 0 0 1px $color-gray-400;
			border-radius: 4px;
		}

		td,
		th {
			padding: rem(12);
		}

		tr {
			> td, > th {
				padding-left: rem(16);
			}
		}

		.w-20 {
			width: 20%;
		}
	}

	.icon-edit-plan,
	.btn-import,
	.btn-export {
		@include typo-body-2;

		cursor: pointer;
		color: $color-black-45;
		font-weight: 500;

		&:hover {
			color: $color-orange;
		}
	}
</style>
