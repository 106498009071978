var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table-installment w-100 typo-body-2 mt-4"},[_vm._m(0),_c('tbody',_vm._l((_vm.plans),function(plan){return _c('tr',{key:plan.id},[_c('td',[_vm._v(_vm._s(plan.interestRate)+"%")]),_c('td',[_vm._v(_vm._s(plan.period)+" month(s)")]),_c('td',[(plan.minOrderAmount)?[_vm._v(" "+_vm._s(_vm._f("priceFormat")(plan.minOrderAmount))+" ")]:[_vm._v(" - ")]],2),_c('td',[(plan.maxOrderAmount)?[_vm._v(" "+_vm._s(_vm._f("priceFormat")(plan.maxOrderAmount))+" ")]:[_vm._v(" - ")]],2),_c('td',[_c('router-link',{staticClass:"icon-edit-plan",attrs:{"to":{
						name: _vm.channelId ? 'InstallmentPlanChannelEdit' : 'InstallmentPlanEdit',
						params: {
							id: _vm.groupId,
							methodId: _vm.methodId,
							channelId: _vm.channelId,
							planId: plan.id,
						}
					}}},[_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cil-pencil"}})],1)],1)])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v(" Interest rate ")]),_c('th',[_vm._v("Term")]),_c('th',[_vm._v("Minimum order")]),_c('th',[_vm._v("Maximum order")]),_c('th')])])}]

export { render, staticRenderFns }