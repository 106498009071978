<template>
	<table class="table-installment w-100 typo-body-2 mt-4">
		<thead>
			<tr>
				<th>
					Interest rate
				</th>
				<th>Term</th>
				<th>Minimum order</th>
				<th>Maximum order</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="plan in plans" :key="plan.id">
				<td>{{ plan.interestRate }}%</td>
				<td>{{ plan.period }} month(s)</td>
				<td>
					<template v-if="plan.minOrderAmount">
						{{ plan.minOrderAmount | priceFormat }}
					</template>
					<template v-else>
						-
					</template>
				</td>
				<td>
					<template v-if="plan.maxOrderAmount">
						{{ plan.maxOrderAmount | priceFormat }}
					</template>
					<template v-else>
						-
					</template>
				</td>
				<td>
					<router-link
						:to="{
							name: channelId ? 'InstallmentPlanChannelEdit' : 'InstallmentPlanEdit',
							params: {
								id: groupId,
								methodId: methodId,
								channelId: channelId,
								planId: plan.id,
							}
						}"
						class="icon-edit-plan"
					>
						<CIcon class="mr-2" name="cil-pencil" />
					</router-link>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	name: 'BaseTableInstallment',

	props: {
		groupId: {
			type: Number,
			default: null,
		},
		methodId: {
			type: Number,
			default: null,
		},
		plans: {
			type: Array,
			default: () => [],
		},
		channelId: {
			type: Number,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
	.table-installment {
		color: $color-black-90;

		tbody {
			box-shadow: 0 0 0 1px $color-gray-400;
			border-radius: 4px;
		}

		td,
		th {
			padding: rem(12);

			&:first-child {
				width: 20%;
			}
		}

		tr {
			> td, > th {
				padding-left: rem(16);
			}
		}
	}

	.icon-edit-plan {
		@include typo-body-2;

		cursor: pointer;
		color: $color-black-45;
		font-weight: 500;

		&:hover {
			color: $color-orange;
		}
	}
</style>
